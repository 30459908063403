import {SingleDevice, SingleDeviceType} from '../../models/single-device.model';
import {SingleLinkType, SingleLink, ConnectionType, SingleLinkStatus, LinkVendorStatus, BasicLink} from '../../models/single-link.model';
import {Topology} from '../../models/topology';
import {FabricStatus} from '../../models/fabrics.model';
import {VenueData, VenueType} from "../../models/venues.model";

export const disconnectedSingleDevice: SingleDevice = {
  device: {
    id: '1',
    name: 'Alfa',
    vendorName: 'Meraki',
    serialNumber: '666',
    model: 'MS',
    type: SingleDeviceType.Router,
    description: 'Flow',
    createdAt: '2000-11-11',
    lat: 31.767245,
    lon: 35.225685
  },
  kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45},
  health: 20,
  issues: ['20'],
  healthDegradationReasons: [{percentage: 45, reason: 'Not enough electricity'}],
  lastKpiUpdate: 2452342342
};

export const singleDevice: SingleDevice = {
  device: {
    id: '3',
    name: 'Gama',
    vendorName: 'Meraki',
    serialNumber: '666',
    model: 'MS',
    type: SingleDeviceType.Switch,
    description: 'Flow',
    createdAt: '2000-11-11',
    lat: 31.767245,
    lon: 35.225685
  }, kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}, health: 90, issues: ['20'], lastKpiUpdate: 112312313
};


export const fabric: any = {
  'fabric': {
    'id': 1010,
    'name': 'SSID_0_SANITY',
    'fabricStatus': {
      'status': FabricStatus.COMPLETE,
      'reason': null
    },
    'connectedClients': 0,
    'tenantId': 1,
    'properties': null,
    'ssids': [
      {
        'id': 8,
        'name': 'SSID_0_SANITY',
        'description': 'encryption:psk, vlan tag: 200',
        'parameters': {
          'splashPageProfile': 'None',
          'minBitrate': 24.0,
          'ipAssignmentMode': 'Bridge mode',
          'externalId': '0',
          'psk': '12345678',
          'vendorId': '0',
          'lanIsolationEnabled': false,
          'bands': '20',
          'vendorName': 'meraki',
          'ssidAdminAccessible': false,
          'wpaEncryptionMode': 'WPA1 and WPA2',
          'defaultVlanId': 200,
          'authMode': 'psk',
          'perClientBandwidthLimitUp': 0,
          'useVlanTagging': 'true',
          'number': 0,
          'apiVersion': 'v0',
          'encryptionMode': 'wpa',
          'tagsToVlans': {
            'rnd': '100',
            'test': '150'
          },
          'name': 'SSID_0_SANITY',
          'networkId': 'L_682858293500071313',
          'id': 8,
          'perClientBandwidthLimitDown': 0,
          'status': 'true'
        }
      }
    ],
    'vlans': [
      {
        'id': 25,
        'name': 'V100_Igor_Tests',
        'description': 'subnet:192.168.100.0/24',
        'externalId': null,
        'networkId': null,
        'applianceIp': null,
        'subnet': null,
        'dnsNameservers': null,
        'parameters': {
          'dnsNameservers': 'upstream_dns',
          'subnet': '192.168.100.0/24',
          'dhcpHandling': 'Run a DHCP server',
          'dhcpOptions': [],
          'externalId': '100',
          'vendorId': '100',
          'dhcpBootOptionsEnabled': false,
          'vendorName': 'meraki',
          'fixedIpAssignments': {},
          'dhcpLeaseTime': '1 day',
          'apiVersion': 'v0',
          'tagsToVlans': {},
          'name': 'V100_Igor_Tests',
          'networkId': 'L_682858293500071313',
          'id': 25,
          'applianceIp': '192.168.100.1',
          'reservedIpRanges': [],
          'status': null
        }
      },
      {
        'id': 26,
        'name': 'V2000_SANITY_Tests',
        'description': 'subnet:20.10.200.0/24',
        'externalId': null,
        'networkId': null,
        'applianceIp': null,
        'subnet': null,
        'dnsNameservers': null,
        'parameters': {
          'dnsNameservers': 'upstream_dns',
          'subnet': '20.10.200.0/24',
          'dhcpHandling': 'Run a DHCP server',
          'dhcpOptions': [],
          'externalId': '2000',
          'vendorId': '2000',
          'dhcpBootOptionsEnabled': false,
          'vendorName': 'meraki',
          'fixedIpAssignments': {},
          'dhcpLeaseTime': '1 day',
          'apiVersion': 'v0',
          'tagsToVlans': {},
          'name': 'V2000_SANITY_Tests',
          'networkId': 'L_682858293500071313',
          'id': 26,
          'applianceIp': '20.10.200.1',
          'reservedIpRanges': [],
          'status': null
        }
      },
      {
        'id': 18,
        'name': 'Default2',
        'description': 'subnet:192.168.128.0/24',
        'externalId': null,
        'networkId': null,
        'applianceIp': null,
        'subnet': null,
        'dnsNameservers': null,
        'parameters': {
          'dnsNameservers': 'upstream_dns',
          'subnet': '192.168.128.0/24',
          'dhcpHandling': 'Run a DHCP server',
          'dhcpOptions': [],
          'externalId': '1',
          'vendorId': '1',
          'dhcpBootOptionsEnabled': false,
          'vendorName': 'meraki',
          'fixedIpAssignments': {},
          'dhcpLeaseTime': '1 week',
          'apiVersion': 'v0',
          'tagsToVlans': {},
          'name': 'Default2',
          'networkId': 'L_682858293500071313',
          'id': 18,
          'applianceIp': '192.168.128.1',
          'reservedIpRanges': [],
          'status': null
        }
      }
    ]
  },
  'kpis': {
    'latency': 10,
    'users': 2,
    'traffic': 300,
    'loss': 0.1,
    'utilization': 99.9
  },
  'health': '100.0',
  'healthDegradationReasons': []
};


export const singleVenue: VenueData = {
  venue: {
    id: 1,
    name: 'MS-100',
    address: 'Hebron Rd 24, Jerusalem',
    lat: 31.767245,
    long: 35.225685,
    description: 'power connection fabric',
    createdAt: '2019-01-01',
    devicesNo: 5,
    fabricsNo: 3,
    tenantId: 2,
    phone: '455-494594-5454',
    type: VenueType.APARTMENT,
    properties: [
      {key: 'LAN IP', value: '234'},
      {key: 'VLAN', value: '129'},
      {key: 'PUBLIC IP', value: '342'},
      {key: 'GATEWAY', value: '129'},
      {key: 'DNS', value: '129'},
      {key: 'SERIAL NUMBER', value: '454'}]
  },
  health: 74,
  kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}
};

export const fabricNodes: SingleDevice[] = [
  {
    device: {
      id: '0',
      name: 'Alfa',
      vendorName: 'Meraki',
      serialNumber: '666',
      model: 'MS',
      type: SingleDeviceType.WWW,
      description: 'Flow',
      createdAt: '2000-11-11',
      lat: 31.767245,
      lon: 35.225685
    }, kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}, health: 20, issues: ['20'], lastKpiUpdate: 2312154
  },
  {
    device: {
      id: '2',
      name: 'Alfa',
      vendorName: 'Meraki',
      serialNumber: '666',
      model: 'MS',
      type: SingleDeviceType.AccessPoint,
      description: 'Flow',
      createdAt: '2000-11-11',
      lat: 31.767245,
      lon: 35.225685
    }, kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}, health: 66, issues: ['20'], lastKpiUpdate: 2312154
  },
  {
    device: {
      id: '3',
      name: 'Alfa',
      vendorName: 'Meraki',
      serialNumber: '666',
      model: 'MS',
      type: SingleDeviceType.Router,
      description: 'Flow',
      createdAt: '2000-11-11',
      lat: 31.767245,
      lon: 35.225685
    }, kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}, health: 90, issues: ['20'], lastKpiUpdate: 2312154
  },
  {
    device: {
      id: '4',
      name: 'Alfa',
      vendorName: 'Meraki',
      serialNumber: '666',
      model: 'MS',
      type: SingleDeviceType.SDWAN,
      description: 'Flow',
      createdAt: '2000-11-11',
      lat: 31.767245,
      lon: 35.225685
    }, kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}, health: 90, issues: ['20'], lastKpiUpdate: 2312154
  },
  {
    device: {
      id: '12',
      name: 'Alfa',
      vendorName: 'Meraki',
      serialNumber: '666',
      model: 'MS',
      type: SingleDeviceType.AccessPoint,
      description: 'Flow',
      createdAt: '2000-11-11',
      lat: 31.767245,
      lon: 35.225685
    }, kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}, health: 81, issues: ['20'], lastKpiUpdate: 2312154
  },
];
export const fabricLinks: SingleLink[] = [
  {
    id: '1',
    type: SingleLinkType.Wired,
    origin: LinkVendorStatus.MULTI_VENDOR,
    logicalConnections: [{id: 1, externalId: 'x1', type: ConnectionType.SSID, name: '90210'}],
    status: SingleLinkStatus.OK,
    description: 'traffic',
    startDeviceId: '2',
    startPort: '12',
    startPortDetails: [{key: 'start', value: '800'}],
    endDeviceId: '3',
    endPort: '76',
    endPortDetails: [{key: 'start', value: '800'}]
  },
  {
    id: '2',
    type: SingleLinkType.Wired,
    origin: LinkVendorStatus.MULTI_VENDOR,
    logicalConnections: [{id: 2, externalId: 'x2', type: ConnectionType.VLAN, name: '90210'}],
    status: SingleLinkStatus.OK,
    description: 'traffic',
    startDeviceId: '3',
    startPort: '12',
    startPortDetails: [{key: 'start', value: '800'}],
    endDeviceId: '12',
    endPort: '76',
    endPortDetails: [{key: 'start', value: '800'}]
  },
  {
    id: '3',
    type: SingleLinkType.Wireless,
    origin: LinkVendorStatus.MULTI_VENDOR,
    logicalConnections: [{id: 3, externalId: 'x3', type: ConnectionType.SSID, name: '90210'}],
    status: SingleLinkStatus.OK,
    description: 'traffic',
    startDeviceId: '0',
    startPort: '15',
    startPortDetails: [{key: 'start', value: '800'}],
    endDeviceId: '4',
    endPort: '76',
    endPortDetails: [{key: 'start', value: '800'}]
  },
  {
    id: '4',
    type: SingleLinkType.Wired,
    origin: LinkVendorStatus.MULTI_VENDOR,
    logicalConnections: [{id: 4, externalId: 'x4', type: ConnectionType.SSID, name: '90210'}],
    status: SingleLinkStatus.OK,
    description: 'traffic',
    startDeviceId: '3',
    startPort: '15',
    startPortDetails: [{key: 'start', value: '800'}],
    endDeviceId: '4',
    endPort: '76',
    endPortDetails: [{key: 'start', value: '800'}]
  }
];
export const venueNodes: SingleDevice[] = [
  {
    device: {
      id: '0',
      name: 'Alfa',
      vendorName: 'Meraki',
      serialNumber: '666',
      model: 'MS',
      type: SingleDeviceType.WWW,
      description: 'Flow',
      createdAt: '2000-11-11',
      lat: 31.767245,
      lon: 35.225685
    }, kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}, health: 20, issues: ['20'], lastKpiUpdate: null
  },
  {
    device: {
      id: '2',
      name: 'Beta',
      vendorName: 'Meraki',
      serialNumber: '666',
      model: 'MS',
      type: SingleDeviceType.AccessPoint,
      description: 'Flow',
      createdAt: '2000-11-11',
      lat: 31.767245,
      lon: 35.225685
    }, kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}, health: 66, issues: ['20'], lastKpiUpdate: null
  },
  {
    device: {
      id: '3',
      name: 'Gama',
      vendorName: 'Meraki',
      serialNumber: '666',
      model: 'MS',
      type: SingleDeviceType.Switch,
      description: 'Flow',
      createdAt: '2000-11-11',
      lat: 31.767245,
      lon: 35.225685
    }, kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}, health: 90, issues: ['20'], lastKpiUpdate: null
  },
  {
    device: {
      id: '4',
      name: 'Delta',
      vendorName: 'Meraki',
      serialNumber: '666',
      model: 'MS',
      type: SingleDeviceType.SDWAN,
      description: 'Flow',
      createdAt: '2000-11-11',
      lat: 31.767245,
      lon: 35.225685
    }, kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}, health: 90, issues: ['20'], lastKpiUpdate: null
  },
  {
    device: {
      id: '12',
      name: 'Omega',
      vendorName: 'Meraki',
      serialNumber: '666',
      model: 'MS',
      type: SingleDeviceType.AccessPoint,
      description: 'Flow',
      createdAt: '2000-11-11',
      lat: 31.767245,
      lon: 35.225685
    }, kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45}, health: 81, issues: ['20'], lastKpiUpdate: null
  },
];
export const venueLinks: SingleLink[] = [
  {
    id: '1',
    type: SingleLinkType.Wired,
    origin: LinkVendorStatus.MULTI_VENDOR,
    logicalConnections: [{id: 1, externalId: 'x1', type: ConnectionType.SSID, name: '90210'}],
    status: SingleLinkStatus.OK,
    description: 'traffic',
    startDeviceId: '2',
    startPort: '12',
    startPortDetails: [{key: 'start', value: '800'}],
    endDeviceId: '3',
    endPort: '76',
    endPortDetails: [{key: 'start', value: '800'}]
  },
  {
    id: '2',
    type: SingleLinkType.Wired,
    origin: LinkVendorStatus.MULTI_VENDOR,
    logicalConnections: [{id: 2, externalId: 'x2', type: ConnectionType.VLAN, name: '90210'}],
    status: SingleLinkStatus.OK,
    description: 'traffic',
    startDeviceId: '3',
    startPort: '12',
    startPortDetails: [{key: 'start', value: '800'}],
    endDeviceId: '12',
    endPort: '76',
    endPortDetails: [{key: 'start', value: '800'}]
  },
  {
    id: '3',
    type: SingleLinkType.Wireless,
    origin: LinkVendorStatus.MULTI_VENDOR,
    logicalConnections: [{id: 3, externalId: 'x3', type: ConnectionType.SSID, name: '90210'}],
    status: SingleLinkStatus.OK,
    description: 'traffic',
    startDeviceId: '0',
    startPort: '15',
    startPortDetails: [{key: 'start', value: '800'}],
    endDeviceId: '4',
    endPort: '76',
    endPortDetails: [{key: 'start', value: '800'}]
  },
  {
    id: '4',
    type: SingleLinkType.Wired,
    origin: LinkVendorStatus.MULTI_VENDOR,
    logicalConnections: [{id: 4, externalId: 'x4', type: ConnectionType.SSID, name: '90210'}],
    status: SingleLinkStatus.OK,
    description: 'traffic',
    startDeviceId: '3',
    startPort: '15',
    startPortDetails: [{key: 'start', value: '800'}],
    endDeviceId: '4',
    endPort: '76',
    endPortDetails: [{key: 'start', value: '800'}]
  }
];
export const fabricTopology: Topology<SingleDevice, BasicLink> = {nodes: fabricNodes, links: fabricLinks};
export const venueTopology: Topology<SingleDevice, BasicLink> = {nodes: venueNodes, links: venueLinks};
